<template>  
  <flow-form 
    ref="flowform"
    v-bind:questions="questions" 
    v-bind:language="language" 
    @step="onStep"
    @answer="onAnswer"
    @complete="onComplete"
    @submit="onSendData"
  >
    <template v-slot:completeButton>
      <div class="f-submit" v-if="!submitted">
        <button 
          class="o-btn-action"
          ref="button"
          type="submit"
          href="#"
          v-on:click.prevent="onSendData()"
          aria-label="{{ language.ariaSubmitText }}"
        >
          <span>{{ language.submitText }}</span>
        </button>
        <a class="f-enter-desc"
          href="#"
          v-on:click.prevent="onSendData()"
          v-html="language.formatString(language.pressEnter)">
        </a>
      </div>

      <div v-if="submitted">
        <p class="text-success" v-if="attending">{{ language.successText }}</p>
        <p v-else>Schade, dass du nicht dabei sein kannst. Vielen Dank für deine Rückmeldung!</p>
        <button 
          class="o-btn-action"
          ref="button"
          type="button"
          href="#"
          v-on:click.prevent="onReset()"
          aria-label="Klicken um weitere Person einzutragen"
        >
          <span>Weitere Person eintragen</span>
        </button>
        <a class="f-enter-desc"
          href="#"
          v-on:click.prevent="onReset()"
          v-html="language.formatString(language.pressEnter)">
        </a>
        <p><a href="https://potatopotato.love">Zurück zur Hauptseite</a></p>
      </div>
    </template>
  </flow-form>
</template>

<script>
  // Import necessary components and classes
  import { FlowForm, QuestionType, LanguageModel } from '@ditdot-dev/vue-flow-form'
  import deDE from './deDE'  
  import mainQuestions from './questions/main'
  // import gameQuestions from './questions/game'


  const defaultLanguage = deDE

  export default {
    name: 'potato-survey',

    components: {
      FlowForm
    },

    data() {
      return {
        language: new LanguageModel(defaultLanguage),
        questions: [
          ...mainQuestions,
          // ...gameQuestions,
        ],
        submitted: false,
        attending: false,        
        completed: false,
      }
    },

    mounted() {
      document.addEventListener('keyup', this.onKeyListener)
    },

    beforeUnmount() {
      document.removeEventListener('keyup', this.onKeyListener)
    },

    methods: {
      onKeyListener($event) {
        // For the reset button
        if ($event.key === 'Enter' && this.completed && this.submitted) {
          this.onReset()
        }
      },

      onComplete(completed) {
        this.completed = completed
      },

      async onSendData() {
        // Set `submitted` to true so the form knows not to allow back/forward
        // navigation anymore.
        this.$refs.flowform.submitted = true
        this.submitted = true

        const response = this.questions
          .filter(({ type }) => type !== QuestionType.SectionBreak)
          .map(({ id, title, answer} ) => ({ id, title, answer }))
        try {
          const result = await fetch('/api/response', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(response)
          })
          if (!result.ok) {
            console.log(result)
            throw new Error('Code: ' + result.status)
          }
        } catch (e) {
          alert('Fehler beim Abschicken, versuch es bitte nochmal! \n\n' + e.message)
        } 
      },

      onStep(_activeQuestionId, activeQuestion) {
        if (activeQuestion?.otherPrompt) {
          this.language.otherPrompt = activeQuestion?.otherPrompt
        }
      },

      onAnswer(questionAnswered) {
        if (questionAnswered.id === 'attend') {
          this.attending = questionAnswered.answer === 'yes' 
        }
      },

      onReset() {
        window.location.reload()
      }
    }
  }
</script>

<style>
  /* Import Vue Flow Form base CSS */
  @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
  /* Import one of the Vue Flow Form CSS themes (optional) */
  /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css'; */
  /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-green.css'; */
  /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css'; */

  @import 'theme.css';
</style>