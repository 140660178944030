import { LanguageModel } from '@ditdot-dev/vue-flow-form'

const deDE = new LanguageModel({
    enterKey: 'Enter',
    shiftKey: 'Shift',
    ok: 'OK',
    continue: 'Weiter',
    skip: 'Überspringen',
    pressEnter: 'Drücke :enterKey',
    multipleChoiceHelpText: 'Wähle so viele Optionen aus wie du möchtest',
    multipleChoiceHelpTextSingle: 'Wähle genau eine Option aus',
    otherPrompt: 'SHOULD BE OVERRIDDEN, set `otherPrompt` for the question!',
    placeholder: 'Gib hier deine Antwort ein…',
    submitText: 'Abschicken',
    longTextHelpText: ':shiftKey + :enterKey um eine neuen Zeile einzufügen.',
    prev: 'Zurück',
    next: 'Weiter',
    percentCompleted: ':percent% abgeschlossen',
    invalidPrompt: 'Bitte gib einen validen Wert an',
    thankYouText: 'Fertig ausgefüllt!',
    successText: 'Deine Antworten wurden abgeschickt. Wir freuen uns auf die Feier mit dir!',
    ariaOk: 'Drücken um zur nächsten Frage zu kommen',
    ariaRequired: 'Du musste diese Frage beantworten',
    ariaPrev: 'Vorherige Frage',
    ariaNext: 'Nächste Frage',
    ariaSubmitText: 'Drücken zum Abschicke',
    ariaMultipleChoice: 'Drücke :letter um die Option auszuwählen',
    ariaTypeAnswer: 'Gib hier deine Antwort ein',
    // errorAllowedFileTypes: 'Invalid file type. Allowed file types: :fileTypes.',
    // errorMaxFileSize: 'File(s) too large. Maximum allowed file size: :size.',
    // errorMinFiles: 'Too few files added. Minimum allowed files: :min.',
    // errorMaxFiles: 'Too many files added. Maximum allowed files: :max.',
})

export default deDE;