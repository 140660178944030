import { QuestionModel, QuestionType, ChoiceOption } from '@ditdot-dev/vue-flow-form'

const mainQuestions = [
    new QuestionModel({
        type: QuestionType.SectionBreak,
        title: 'RSVP',
        content: "Hi, schön, dass du da bist! Bitte füllt diese Umfrage für jede Person einmal aus. Du willst jemanden mitbringen? Gar kein Problem, lass die Person die Umfrage einfach auch ausfüllen.",
    }),
    new QuestionModel({
        id: "name",
        type: QuestionType.Text,
        title: 'Wie heißt du?',
        required: true,
    }),
    new QuestionModel({
        id: 'attend',
        type: QuestionType.MultipleChoice,
        title: 'Möchtest du zu- oder absagen?',
        required: true,
        options: [
            new ChoiceOption({
                label: 'Ich komme gerne zu eurer Feier!',
                value: 'yes',
            }),
            new ChoiceOption({
                label: 'Ich kann leider nicht kommen.',
                value: 'no',
            }),
        ],
        jump: {
            'no': '_submit'
        }
    }),
    new QuestionModel({
        id: 'photos',
        type: QuestionType.MultipleChoice,
        required: true,
        title: 'Dürfen die Fotos veröffentlicht werden, die bei der Feier von dir gemacht werden?',
        subtitle: 'Unsere Fotografin würde gern eine Auswahl von Bilder auf ihrer Webseite veröffentlichen.',
        options: [
            new ChoiceOption({
                label: 'Ja, kein Problem.',
            }),
            new ChoiceOption({
                label: 'Nein, das möchte ich nicht.',
            }),
        ],
    }),
    new QuestionModel({
        id: 'reuse',
        type: QuestionType.MultipleChoice,
        title: 'Möchtest du, dass wir deine Antworten von der Umfrage aus dem letzten Jahr wiederverwenden?',
        required: true,
        options: [
            new ChoiceOption({
                label: 'Ja, das stimmt alles noch so.',
                value: 'yes',
            }),
            new ChoiceOption({
                label: 'Nein, ich möchte gern neu antworten.',
                value: 'no',
            }),
        ],
        jump: {
            'yes': '_submit'
        }
    }),
    new QuestionModel({
        id: 'plusone',
        type: QuestionType.MultipleChoice,
        required: true,
        title: 'Haben wir dich direkt eingeladen, oder wirst du von jemandem mitgebracht?',
        allowOther: true,
        otherPrompt: 'Ich werde mitgebracht von… ',
        options: [
            new ChoiceOption({
                label: 'Ich habe eine Einladung von euch bekommen.',
                value: 'no',
            }),
        ],
    }),
    new QuestionModel({
        id: "email",
        type: QuestionType.Email,
        title: 'Unter welcher Mail-Adresse können wir dich erreichen?',
        subtitle: 'Wir werden dir an diese Adresse schreiben, falls wir kurzfristig zusätzliche Informationen verteilen müssen.'
    }),
    new QuestionModel({
        id: "food",
        type: QuestionType.LongText,
        title: 'Hast du irgendwelche Lebensmittel-Allergien oder Unverträglichkeiten?',
        subtitle: 'Bei welchen Zutaten brauchst du einen Hinweis, wenn sie enthalten sind? Gerne so ausführlich wie nötig!'
    }),
    new QuestionModel({
        id: 'beerBenches',
        type: QuestionType.MultipleChoice,
        required: true,
        title: 'Wir werden zumindest zum Teil Bierbänke verwenden. Kannst du dadrauf sitzen?',
        options: [
            new ChoiceOption({
                label: 'Ja, kein Problem.',
            }),
            new ChoiceOption({
                label: 'Ja, aber nur zum Essen.',
            }),
            new ChoiceOption({
                label: 'Nein, ich brauche eine Rückenlehne.',
            }),
            new ChoiceOption({
                label: 'Nein, ich brauche Rücken- und Armlehnen.',
            }),
        ],
    }),
    new QuestionModel({
        id: 'hotelHelp',
        type: QuestionType.MultipleChoice,
        title: 'Möchtest du Hilfe dabei, ein Hotel o.ä. zu finden?',
        required: true,
        options: [
            new ChoiceOption({
                label: 'Ja',
            }),
            new ChoiceOption({
                label: 'Nein',
            }),
        ],
    }),
    new QuestionModel({
        id: 'bringFood',
        type: QuestionType.MultipleChoice,
        title: 'Möchtest du eine Kleinigkeit für unser Buffet mitbringen?',
        required: true,
        options: [
            new ChoiceOption({
                label: 'Ja',
            }),
            new ChoiceOption({
                label: 'Nein',
            }),
            new ChoiceOption({
                label: 'Weiß ich noch nicht',
            }),
        ],
    }),
    new QuestionModel({
        id: "other",
        type: QuestionType.LongText,
        title: 'Gibt es sonst noch etwas, das du brauchst und das wir für dich vorbereiten sollen, oder etwas anderes, das wir wissen sollen?',
    }),
    // new QuestionModel({
    //     id: 'game',
    //     type: QuestionType.MultipleChoice,
    //     required: true,
    //     title: 'Wir organisieren ein Spiel mit den Gästen während der Party. Möchtest du mitspielen?',
    //     subtitle: 'Bei dem Spiel füllst du vor der Party einen Fragebogen aus und versuchst dann während der Party, die Antworten der anderen Gäste herauszufinden.',
    //     options: [
    //         new ChoiceOption({
    //             label: 'Ja, ich würde meine Antworten gern neu schreiben.',
    //             value: 'yes_new',
    //         }),
    //         new ChoiceOption({
    //             label: 'Ja, nehmt meine Antworten vom letzten Mal.',
    //             value: 'yes_reuse',
    //         }),
    //         new ChoiceOption({
    //             label: 'Nein, danke.',
    //             value: 'no',
    //         }),
    //     ],
    //     jump: {
    //         'no': '_submit',
    //         'yes_reuse': '_submit',
    //     }
    // }),
    // new QuestionModel({
    //     type: QuestionType.SectionBreak,
    //     title: 'Schön, dass du beim Kennlernspiel dabei sein möchtest!',
    //     content: "Beantworte einfach die folgenden Fragen, um dabei zu sein. ",
    //     description: " Ein paar Hinweise: Wir werden deine Antworten mit den anderen Gästen teilen. Du musst nicht alle Fragen beantworten, aber je mehr Fragen du beantwortest, desto interessanter wird es. Du brauchst deine Antworten bei der Feier wieder, aber keine Sorge, wir werden sie zusammen mit dem anderen Spielmaterial für dich ausdrucken.",
    // }),
]

export default mainQuestions;